/** @jsx jsx */
import { jsx, Box, Flex, Styled } from "theme-ui"
import Img from "gatsby-image"
import { Link } from "gatsby"

export default ({ producers }) => {
  return (
    <Flex sx={{flexWrap: 'wrap', justifyContent: 'center'}}>
      {producers.map(({ node }, i) => (
        <Box key={i} sx={{ width: ['100%', '50%', '33.3333%'], px: [0, 3, 4], mb: 4 }}>
          <Img
            fluid={{...node.images[0].fluid, aspectRatio: 1}}
            imgStyle={{ objectFit: "contain", objectPosition: 'bottom left' }}
          />
          <Box sx={{ py: 3, }}>
            <Styled.h2
              sx={{
                mt: 0,
                mb: 2,
              }}
            >
              {node.name}
            </Styled.h2>
            <Box sx={{ fontFamily: "display", fontSize: [2] }}>
              {node.description.childMarkdownRemark.excerpt}{" "}
              <Styled.a sx={{ fontFamily: 'heading'}} as={Link} to={`/tuottajat/${node.slug}`}>
                Lue lisää &rsaquo;
              </Styled.a>
            </Box>
          </Box>
        </Box>
      ))}
    </Flex>
  )
}
