/** @jsx jsx */
import { Styled, jsx } from "theme-ui"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProducerList from "../components/producerList"

const ProducerPage = ({ data }) => (
  <Layout fullWidth>
    <SEO title="Tuottajat" />
    <Styled.h1 sx={{ textAlign: "center", mb: [4, 5] }}>Tuottajat</Styled.h1>
    <ProducerList producers={data.allContentfulProducer.edges} />
  </Layout>
)

export default ProducerPage

export const query = graphql`
  query {
    allContentfulProducer(sort: { fields: name }) {
      edges {
        node {
          name
          slug
          description {
            childMarkdownRemark {
              excerpt(truncate: true)
            }
          }
          images {
            fluid(maxWidth: 720) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
  }
`
